import React, { Component } from 'react'
import {
	IonApp,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Navigation from '../../Navigation/ionic'

import { withAuthentication } from '../../Session'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import '../../../theme/variables.css'
import { compose } from 'recompose'
import { inject } from 'mobx-react'

/* Global styles */
import '../../../theme/app.scss'
import '../../Reusables/page.scss'

// const App: React.FC = () => (
//   <IonApp>
//     <IonReactRouter>

//         <Navigation />

//     </IonReactRouter>
//   </IonApp>
// );

class App extends Component {

	componentDidMount() {
		if (!this.handlerRegistered) {
			this.handlerRegistered = this.props.registerAuthListener((authUser) => {
				this.props.sessionStore.setDbAuthenticated(!!authUser)
			})
		}
	}

	render() {
		return (
			<IonApp>
				<IonReactRouter>

					<Navigation />

				</IonReactRouter>
			</IonApp>
		)
	}
}


export default compose(
	withAuthentication,
	inject('sessionStore')
) (App)
