const UNITS = [
	'pc',
	'g',
	'kg',
	'l',
	'ml',
	'oz',
	'Stück',
	'Packungen'
]

export default UNITS